import React, {useState} from 'react';
import "./ContentIconList.scss";
import ContentIcon from "../ContentIcon/ContentIcon";
import EditIcon from "../../../assets/images/blue-edit-icon.svg";
import FavoriteIcon from "../../../assets/images/blue-favorite-icon.svg";
import LaterIcon from "../../../assets/images/blue-later-icon.svg";
import PlaylistIcon from "../../../assets/images/blue-playlist-icon.svg";
import ShareIcon from "../../../assets/images/blue-share-icon.svg";
import LearningIcon from "../../../assets/images/blue-learning.svg";
import AddInCollectionPopUp from "./AddInCollectionPopUp/AddInCollectionPopUp";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {addFavorite} from "../../../redux/actions/favorite";
import BluePopUp from "../../global/BluePopUp/BluePopUp";
import IsFetchingIcon from "./IsFetchingIcon/IsFetchingIcon";
import {addContentToList} from "../../../redux/action-creators/learningList";

const ContentIconList = () => {
  const history = useHistory();
  const content = useSelector(state => state.content);
  const learningList = useSelector(state => state.learningList.list);
  const userData = useSelector(state => state.user?.data);
  const favorite = useSelector(state => state.favorite);
  const seeLater = useSelector(state => state.seeLater);
  const [openBluePopJourney, setOpenBluePopJourney] = useState(false);
  const [openBluePopJourneyMessage, setOpenBluePopJourneyMessage] = useState("");
  const [openAddJourney, setOpenAddJourney] = useState(false);
  const [openFavoritePopUp, setOpenFavoritePopUp] = useState(false);
  const [favoriteMessage, setFavoriteMessage] = useState("");
  const [OpenSeeLaterPopUp, setOpenSeeLaterPopUp] = useState(false);
  const [seeLaterMessage, setSeeLaterMessage] = useState("");
  const [openSharePopUp, setOpenSharePopUp] = useState(false);
  const [openLearningPopUp, setOpenLearningPopUp] = useState(false);
  const [learningMessage, setLearningMessage] = useState("");
  const {contentId} = useParams();
  const dispatch = useDispatch();

  const addContentInFavorite = e => {
    e.preventDefault()
    const payload = {
      platform: "ALEXANDRIA"
    }
    dispatch(addFavorite(contentId, payload))
      .then(() => {
        setOpenFavoritePopUp(true)
        setFavoriteMessage("Conteúdo adicionado aos itens favoritos")
      })
      .catch(() => {
        setOpenFavoritePopUp(true)
        setFavoriteMessage("")
      });
  }

  const addContentInSeeLater = e => {
    e.preventDefault()
    const payload = {
      platform: "ALEXANDRIA"
    }
    dispatch(addFavorite(contentId, payload))
      .then(() => {
        setOpenSeeLaterPopUp(true)
        setSeeLaterMessage(`Conteúdo adicionado ao "Ver Mais Tarde"`)
      })
      .catch(() => {
        setOpenSeeLaterPopUp(true)
        setSeeLaterMessage("")
      });
  }

  const copyToLinkShare = () => {
    let textField = document.createElement('textarea')
    textField.innerText = window.location.href;
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    setOpenSharePopUp(true)
  }

  const addLearningList = (e) => {
    const hasContent = learningList.filter((itemList) => itemList.id === content.data.id);
    if (Object.keys(content.data).length > 0 && hasContent.length === 0) {
      dispatch(addContentToList(content.data))
      setOpenLearningPopUp(true)
      setLearningMessage("Conteúdo adicionado a lista de aprendizado")
   }else {
      setOpenLearningPopUp(true)
      setLearningMessage("Você já possui este conteúdo na lista")
    }
  }

  return (
    <div className="ContentIconList">
      <div className="list-title">
        <p>HOME/ARTIGO/<strong>CONTEÚDO</strong></p>
      </div>
      {favorite?.isFetching ? <IsFetchingIcon/> : <ContentIcon
        textButton="favoritar"
        urlButton={FavoriteIcon}
        onClick={(e) => {
          userData && userData.username ? addContentInFavorite(e) : history.push("/login")
        }}/>}
      {seeLater?.isFetching ? <IsFetchingIcon/> : <ContentIcon
        textButton="ver mais tarde"
        urlButton={LaterIcon}
        onClick={(e) => {
          userData && userData.username ? addContentInSeeLater(e) : history.push("/login")
      }}/>}
      <ContentIcon textButton="adicionar a playlist" urlButton={PlaylistIcon} onClick={() =>
        userData && userData.username ? setOpenAddJourney(true) : history.push("/login")
      }/>
      <ContentIcon textButton="lista de aprendizado" urlButton={LearningIcon} onClick={(e) =>
        userData && userData.username ? addLearningList(e) : history.push("/login")
      }/>
      <ContentIcon textButton="compartilhar" urlButton={ShareIcon}
                   onClick={() => copyToLinkShare()}/>
      {openBluePopJourney && <BluePopUp text={openBluePopJourneyMessage} onClick={() => {
        setOpenBluePopJourney(false)
      }}/>}
      {openAddJourney && <AddInCollectionPopUp
        setOpenAddJourney={setOpenAddJourney}
        setOpenBluePopJourney={setOpenBluePopJourney}
        setOpenBluePopJourneyMessage={setOpenBluePopJourneyMessage}/>}
      {openFavoritePopUp &&
      <BluePopUp text={favoriteMessage} onClick={() => setOpenFavoritePopUp(false)}/>}
      {OpenSeeLaterPopUp &&
      <BluePopUp text={seeLaterMessage} onClick={() => setOpenSeeLaterPopUp(false)}/>}
      {openSharePopUp &&
      <BluePopUp text="Link copiado para área de transferência" onClick={() => setOpenSharePopUp(false)}/>}
      {openLearningPopUp &&
      <BluePopUp text={learningMessage} onClick={() => setOpenLearningPopUp(false)}/>}
    </div>
  );
};

export default ContentIconList;
