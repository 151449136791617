import {
  apiUserForgotPasswordRequest,
  apiUserNewPasswordRequest,
  apiInfoRequest,
  apiUpdateUserInfoRequest,
  apiUserUpdatePasswordRequest, apiUploadPhoto
} from "../api/user";
import {
  userForgotPasswordRequest,
  userForgotPasswordRequestFailed,
  userForgotPasswordRequestSucceeded,
  userNewPasswordRequest,
  userNewPasswordRequestFailed,
  userNewPasswordRequestSucceeded,
  userInfoRequest,
  userInfoRequestFailed,
  userInfoRequestSucceeded,
  updateUserInfoRequestSucceeded,
  updateUserInfoRequestFailed,
  updateUserInfoRequest,
  userUpdatePasswordRequest,
  userUpdatePasswordRequestSucceeded,
  userUpdatePasswordRequestFailed, uploadPhotoRequest, uploadPhotoRequestSucceeded, uploadPhotoRequestFailed
} from "../action-creators/user";
import {getItemFromStorage} from "../../helper/storage";
import {USER_ID} from "../constants/local";

const requestForgotPassword = (token, email) => dispatch => {
  dispatch(userForgotPasswordRequest());
  return apiUserForgotPasswordRequest(token, email)
    .then(json => {
      dispatch(userForgotPasswordRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(json => {
      dispatch(userForgotPasswordRequestFailed(json))
      return Promise.reject();
    });
};

export const forgotPassword = (token, email) => dispatch => dispatch(requestForgotPassword(token, email));

const requestNewPassword = (token, code, payload) => dispatch => {
  dispatch(userNewPasswordRequest());
  return apiUserNewPasswordRequest(token, code, payload)
    .then(json => {
      dispatch(userNewPasswordRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(json => {
      dispatch(userNewPasswordRequestFailed(json))
      return Promise.reject();
    });
};

export const newPasswordToUser = (token, code, payload) => dispatch => dispatch(requestNewPassword(token, code, payload));

const getUserRequest = (token) => dispatch => {
  dispatch(userInfoRequest());
  return apiInfoRequest(token)
    .then(json => {
      dispatch(userInfoRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(response => {
        dispatch(userInfoRequestFailed(response));
        return Promise.reject();
      }
    );
};

export const getUserInfo = token => dispatch => dispatch(getUserRequest(token));

const updateUserInfo = (payload) => dispatch => {
  dispatch(updateUserInfoRequest());
  return apiUpdateUserInfoRequest(payload)
    .then(json => {
      dispatch(updateUserInfoRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(response => {
      dispatch(updateUserInfoRequestFailed(response));
      return Promise.reject(response);
    });
};

export const updateUserDetails = (payload) => dispatch => dispatch(updateUserInfo(payload));

const requestUpdateNewPassword = (payload) => dispatch => {
  const userID = localStorage.getItem('USER_ID');
  dispatch(userUpdatePasswordRequest());
  return apiUserUpdatePasswordRequest(userID, payload)
    .then(json => {
      dispatch(userUpdatePasswordRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch(json => {
      dispatch(userUpdatePasswordRequestFailed(json))
      return Promise.reject();
    });
};

export const updateNewPassword = (payload) => dispatch => dispatch(requestUpdateNewPassword(payload));

const uploadUserPhotoRequest = payload => dispatch => {
  const userID = getItemFromStorage(USER_ID)
  dispatch(uploadPhotoRequest());
  return apiUploadPhoto(payload, userID)
    .then(json => {
      dispatch(uploadPhotoRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(response => {
      dispatch(uploadPhotoRequestFailed(response));
      return Promise.reject(response);
    });
};

export const uploadPhoto = payload => dispatch => dispatch(uploadUserPhotoRequest(payload));
