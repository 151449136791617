import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "../../../assets/images/nav-search-zoom.svg";
import LogoLogin from "../../../assets/images/open-account-login.svg";
import { getToken, getUserStorage } from "../../../helper/loginStorage";
import { getItemFromStorage } from "../../../helper/storage";
import { getTagsRequest } from "../../../redux/actions/tags";
import Login from "../../entry/Entry/Entry";
import Input from "../form/Input/Input";
import NavigationLink from "../NavigationLink/NavigationLink";
import "./Navbar.scss";
import SearchNavbar from "./SearchNavbar/SearchNavbar";

const Navbar = ({ setActiveTag }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openLibrary, setOpenLibrary] = useState(false);
  const tags = useSelector((state) => state.tags?.data);
  const [valueSearch, setValueSearch] = useState("");
  const [isOnSearchPage, setIsOnSearchPage] = useState(false);
  const userStorage = JSON.parse(getUserStorage());
  const [activeLogin, setActiveLogin] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const isSearchPage = window.location.href.includes("/pesquisa?");
    if (isSearchPage) {
      setIsOnSearchPage(true);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1440);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const resultInput = !valueSearch
    ? tags
    : tags
      .filter((item) =>
        item.name.toLowerCase().includes(valueSearch.toLocaleLowerCase())
      )
      .filter((item) => item.name !== valueSearch);

  const searchList = Array.isArray(resultInput) && resultInput?.slice(0, 10);

  useEffect(() => {
    if (!tags) {
      dispatch(getTagsRequest());
    }
  }, [dispatch]);

  const handleClick = (nome) => {
    setValueSearch(nome);
    history.push("/pesquisa?search=" + nome);
    if (isOnSearchPage) {
      window.location.reload();
    }
  };

  const logoutStorage = () => {
    localStorage.clear();
    history.push("/");
  };

  const handleTagClick = (nome) => {
    setValueSearch(nome);
    history.push("/pesquisa?tags=" + nome);
    if (isOnSearchPage) {
      window.location.reload();
    }
  };

  return (
    <>
      <nav className="navbar">
        <div className={`navbar-container ${isMobile ? "mobile" : ""}`}>
          <div className="navbar-left">
            <div className="navbar-menu" onClick={() => setOpen(!open)}>
              <span />
              <span />
              <span />
            </div>
            <h1
              onClick={() => {
                history.push("/");
                pathname === "/" && setActiveTag(false);
              }}
            >
              AlexandrIA
            </h1>
            <div className="navbar-menu-bar">
              <NavigationLink to="/" active={pathname === "/"}>
                Home
              </NavigationLink>
              <NavigationLink to="/sobre" active={pathname === "/sobre"}>
                Sobre
              </NavigationLink>
              <div className="library-container">
                <button
                  className="library-button"
                  onClick={() => setOpenLibrary(!openLibrary)}
                >
                  Minha biblioteca
                </button>
                <div
                  className={`library-menu-info ${openLibrary ? "show" : ""}`}
                >
                  <button
                    onClick={() => history.push("/perfil/meus-conteudos")}
                  >
                    {" "}
                    Meus conteúdos
                  </button>
                  <button onClick={() => history.push("/perfil/colecao")}>
                    {" "}
                    Coleções
                  </button>
                  <button onClick={() => history.push("/perfil/salvos")}>
                    {" "}
                    Salvos
                  </button>
                  <button onClick={() => history.push("/perfil/notas")}>
                    {" "}
                    Comentários
                  </button>
                  <button onClick={() => history.push("/perfil/historico")}>
                    {" "}
                    Histórico
                  </button>
                </div>
              </div>
              <NavigationLink to="/extensao" active={pathname === "/extensao"}>
                Extensão +
              </NavigationLink>
            </div>
          </div>
          {open ? (
            <div className="navbar-menu-info">
              <button onClick={() => history.push("/")}> Home</button>
              <button onClick={() => history.push("/extensao")}>
                {" "}
                Conheça nossa extensão
              </button>
              <button onClick={() => history.push("/perfil/colecao")}>
                {getToken() ? "Área do usuário" : "Fazer Login"}
              </button>
              {getToken() && (
                <button onClick={() => logoutStorage()}>Sair da conta</button>
              )}
            </div>
          ) : (
            ""
          )}
          <div
            className={`navbar-right  ${valueSearch && searchList.length > 0 && "active"
              }`}
          >
            <div className="container-right">
              <Input
                value={valueSearch}
                type="text"
                placeholder="buscar por autor, título, tema"
                updateState={setValueSearch}
                onEnter={(e) =>
                  e.key === "Enter" &&
                  valueSearch.length > 0 &&
                  handleClick(valueSearch)
                }
              />
              <button type="submit">
                <img src={Logo} alt="Ícone de Lupa" />
              </button>
            </div>
            {valueSearch && searchList.length > 0 && (
              <div
                style={{ display: !valueSearch ? "none" : "flex" }}
                className="result-search-navbar"
              >
                <p className="text-search">
                  TAG:(aqui aparecem sugestões do autocompletar)
                </p>
                <p className="text-search">Sugerido para você:</p>
                <div className="container-search">
                  {searchList.map((item, key) => (
                    <SearchNavbar
                      item={item}
                      key={key}
                      onClick={() => {
                        handleTagClick(item.id);
                        setValueSearch("");
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          {!getItemFromStorage("ALEXANDRIA_AUTH_TOKEN") ? (
            <button
              className="button-login"
              onClick={() => setActiveLogin(true)}
            >
              Login <img src={LogoLogin} />
            </button>
          ) : (
            <div className="div-profile">
              <button
                className="button-login logged"
                onClick={() => setOpenProfile(!openProfile)}
              >
                <p>Olá, {userStorage?.username}!</p>
                <img src={userStorage?.photo} />
              </button>
              {openProfile ? (
                <div className="navbar-menu-info-profile">
                  <button style={{ cursor: "not-allowed" }}>
                    {" "}
                    Notificações
                  </button>
                  <button onClick={() => history.push("/editar/perfil")}>
                    {" "}
                    Editar perfil
                  </button>
                  {getToken() && (
                    <button onClick={() => logoutStorage()}>
                      Sair da conta
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </nav>
      {activeLogin && (
        <Login openDesktop={activeLogin} setOpenDesktop={setActiveLogin} />
      )}
    </>
  );
};

export default Navbar;
