import React, { useEffect, useState } from 'react';
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { getItemFromStorage } from "../../../helper/storage";
import { getContentsRequest } from '../../../redux/actions/contents';
import { infoFavoriteUserList } from '../../../redux/actions/favorite';
import { getRecommendation } from "../../../redux/actions/recommendation";
import { getTagsRequest } from "../../../redux/actions/tags";
import { getUserInfo } from "../../../redux/actions/user";
import { apiContents } from '../../../redux/api/contents';
import ContentCardList from "../ContentCardList/ContentCardList";
import ContentCardListMobile from "../ContentCardListMobile/ContentCardListMobile";
import HomeLanding from "../HomeLanding/HomeLanding";
import LargeMobileCard from "../LargeMobileCard/LargeMobileCard";
import LearningOfTheDay from '../LearningOfTheDay/LearningOfTheDay';
import LoginHome from "../LoginHome/LoginHome";
import PresentationHome from '../PresentationHome/PresentationHome';
import SlideCards from "../SlideCards/SlideCards";
import SlideTags from "../SlideTags/SlideTags";
import SuggestionTagList from "../SuggestionTagList/SuggestionTagList";
import SearchArea from "../searchArea/SearchArea/SearchArea";
import './Home.scss';

const Home = ({ activeTag, setActiveTag }) => {
  const recommendation = useSelector(state => state.recommendation)
  const favorited = useSelector(state => state.favorite)
  const seeLater = useSelector(state => state.seeLater);
  const tags = useSelector(state => state.tags);
  const dispatch = useDispatch();
  let filters = { pageSize: 50 }
  const widthWindow = window.innerWidth;
  const [loadingMostSeen, setLoadingMostSeen] = useState(true);
  const [mostSeen, setMostSeen] = useState({});

  const [loadingMostRecent, setLoadingMostRecent] = useState(true);
  const [mostRecent, setMostRecent] = useState({});

  let oneContentSection = recommendation?.data[0] ? [...recommendation?.data].splice(0, 1) : []
  let firstContentSectionMobile = recommendation?.data[0] ? [...recommendation?.data].splice(1, 8) : []

  let onHighContent = recommendation?.data[0] ? [...recommendation?.data].splice(0, 8) : []
  let secondOnHighContent = recommendation?.data[0] ? [...recommendation?.data].splice(8, 5) : []

  let slideContent = recommendation?.data[0] ? [...recommendation?.data].splice(8, 10) : []
  let thirdContentSection = recommendation?.data[0] ? [...recommendation?.data].splice(18, 5) : []
  useEffect(() => {
    dispatch(getRecommendation(filters)).then((json) => {
      let list = [];
      json.map((item) => {
        list.push(item.id)
      })
      dispatch(infoFavoriteUserList({ ids: list }))
    })
    dispatch(getTagsRequest())
    dispatch(getUserInfo())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(getContentsRequest({ accessCountFilter: "access-count-desc" }));

    apiContents({ accessCountFilter: "access-count-desc" })
      .then((json) => {
        setLoadingMostSeen(false)
        setMostSeen(json)
      })

    apiContents()
      .then((json) => {
        setLoadingMostRecent(false)
        setMostRecent(json)
      })
  }, [dispatch])

  useEffect(() => {
    favorited?.data && favorited?.data?.exists?.flatMap((favoriteID) =>
      recommendation?.data?.filter(({ id }) => favoriteID === id)
    )?.map((response) => {
      recommendation?.data?.map((recommendation) => {
        if (recommendation.id === response.id) {
          recommendation["isFavoriteApresentation"] = true
        }
      })
    })
  }, [favorited]);


  const verifyToken = () => {
    if (getItemFromStorage("ALEXANDRIA_AUTH_TOKEN")) {
      return (
        <React.Fragment>
          <LargeMobileCard isLogged isFetching={recommendation?.isFetching} items={oneContentSection} />
          <ContentCardListMobile isFetching={recommendation?.isFetching} items={firstContentSectionMobile} amount={5} />
          <SlideTags isLogged />
          <SlideCards i sLogged items={slideContent} />
          {thirdContentSection.length >= 1 &&
            <div className="learn-too isLogged">
              <p>APRENDA <strong>TAMBÉM</strong></p>
              <ContentCardListMobile isFetching={recommendation?.isFetching} items={thirdContentSection} amount={5} />
            </div>
          }
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <HomeLanding />
          <LargeMobileCard isFetching={recommendation?.isFetching} items={oneContentSection} />
          <ContentCardListMobile isFetching={recommendation?.isFetching} items={firstContentSectionMobile} amount={4} />
          <SlideTags />
          <SlideCards items={slideContent} />
          <LoginHome />
          {thirdContentSection.length >= 1 &&
            <div className="learn-too">
              <p>APRENDA <strong>TAMBÉM</strong></p>
              <ContentCardListMobile isFetching={recommendation?.isFetching} items={thirdContentSection}
                amount={4} />
            </div>
          }
        </React.Fragment>
      )
    }
  }

  const renderHome = () => {
    if (widthWindow > 1024) {
      if (getItemFromStorage("ALEXANDRIA_AUTH_TOKEN")) {
        return (
          <React.Fragment>
            <div className='high-container logged'>
              <p><strong>Aprendizados em alta</strong></p>
              <ContentCardList isFetching={recommendation?.isFetching} items={onHighContent} amount={4} />
            </div>
            <SlideTags marginTop="-50px" />
            <SlideCards isFetching={loadingMostSeen} items={mostSeen?.items}
              text={[<strong>Em alta hoje</strong>]} marginTop="50px" desktop />
            <SlideCards isFetching={loadingMostRecent} items={mostRecent?.items}
              text={[<strong>Mais recentes</strong>]} marginTop="10px" desktop />
            <div className='high-container logged'>
              <p><strong>Aprenda também</strong></p>
              <ContentCardList isFetching={recommendation?.isFetching} items={secondOnHighContent} amount={4} />
            </div>
            <SlideCards isFetching={seeLater?.isFetching} items={seeLater?.data?.items}
              text={[<strong>Você salvou para ver mais tarde</strong>]} marginTop="10px" desktop />
            <SuggestionTagList items={tags.data} />
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <PresentationHome />
            <SearchArea items={tags.data} activeTag={activeTag} setActiveTag={setActiveTag} />
            <div className='high-container'>
              <p><strong>Em alta hoje</strong></p>
              <ContentCardList isFetching={recommendation?.isFetching} items={onHighContent} amount={8} />
            </div>
            <SlideTags />
            <SlideCards isFetching={loadingMostRecent} items={mostRecent?.items}
              text={[<strong>Mais recentes</strong>]} marginTop="50px" desktop />
            <LearningOfTheDay isFetching={recommendation?.isFetching} items={secondOnHighContent}
              amount={4} text={[<strong>Aprendizados do dia</strong>]}
              marginTop="48px" />
            <SuggestionTagList items={tags.data} />
          </React.Fragment>
        )
      }
    } else {
      return verifyToken()
    }
  }

  return (
    <main className="Home">
      <Helmet title="Home - Alexandria" />
      {renderHome()}
    </main>
  );
};

export default Home;
