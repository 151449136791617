import {
  CHECK_FOLLOW_TAG_REQUEST,
  CHECK_FOLLOW_TAG_REQUEST_FAILED,
  CHECK_FOLLOW_TAG_REQUEST_SUCCEEDED,
  FOLLOW_TAG_REQUEST,
  FOLLOW_TAG_REQUEST_FAILED,
  FOLLOW_TAG_REQUEST_SUCCEEDED,
  TAGS_REQUEST,
  TAGS_REQUEST_FAILED,
  TAGS_REQUEST_SUCCEEDED,
  UNFOLLOW_TAG_REQUEST,
  UNFOLLOW_TAG_REQUEST_FAILED,
  UNFOLLOW_TAG_REQUEST_SUCCEEDED,
  UPDATE_FOLLOW_TAG_REQUEST,
  UPDATE_FOLLOW_TAG_REQUEST_FAILED,
  UPDATE_FOLLOW_TAG_REQUEST_SUCCEEDED,
  SUGGESTION_TAGS_REQUEST,
  SUGGESTION_TAGS_REQUEST_FAILED,
  SUGGESTION_TAGS_REQUEST_SUCCEEDED
} from "../constants/tags";

export const tags = (
  state = {
    isFetching: false,
    data: [],
    error: {},
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case TAGS_REQUEST:
      newState.isFetching = true;
      return newState;

    case TAGS_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case TAGS_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
};

export const checkFollowTag = (
  state = {
    isFetching: false,
    data: [],
    error: {},
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case CHECK_FOLLOW_TAG_REQUEST:
      newState.isFetching = true;
      return newState;

    case CHECK_FOLLOW_TAG_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case CHECK_FOLLOW_TAG_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
};

export const followTag = (
  state = {
    isFetching: false,
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case FOLLOW_TAG_REQUEST:
    case UNFOLLOW_TAG_REQUEST:
    case UPDATE_FOLLOW_TAG_REQUEST:
      newState.isFetching = true;
      return newState;

    case FOLLOW_TAG_REQUEST_SUCCEEDED:
    case UNFOLLOW_TAG_REQUEST_SUCCEEDED:
    case UPDATE_FOLLOW_TAG_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      return newState;

    case FOLLOW_TAG_REQUEST_FAILED:
    case UNFOLLOW_TAG_REQUEST_FAILED:
    case UPDATE_FOLLOW_TAG_REQUEST_FAILED:
      newState.isFetching = false;
      return newState;

    default:
      return newState;
  }
};

export const suggestionTags = (
  state = {
    isFetching: false,
    data: [],
    error: {},
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case SUGGESTION_TAGS_REQUEST:
      newState.isFetching = true;
      return newState;

    case SUGGESTION_TAGS_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case SUGGESTION_TAGS_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
};
