import React, {useEffect, useState} from 'react';
import "./FollowTags.scss";
import SmoothScrolling from "../../helper/smoothScrolling";
import Button from "../global/Button/Button";
import TagFollow from "./TagFollow/TagFollow";
import {useDispatch, useSelector} from "react-redux";
import {updateFollowTag, getTagsRequest} from "../../redux/actions/tags";
import {useHistory} from "react-router-dom";

const FollowTags = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectSuggestion, setSelectSuggestion] = useState([]);
  const tags = useSelector(state => state?.tags?.data);
  const tagsList = [];

  useEffect(() => {
    dispatch(getTagsRequest())
  }, [dispatch])

  tags && tags?.forEach((item) => {
    if(item?.referenceCount * item?.followerCount) {
      tagsList.push(item)
    }
  })

  const tagsReference = tagsList.reverse().splice(0, 50)

  const scrollBottom = () => {
    SmoothScrolling.scrollTo("scrollBottom");
  }

  const handleClicked = (item) => {
    let state = [...selectSuggestion];
    let index = state.indexOf(item);
    if (index === -1) {
      state.push(item)
    } else {
      state.splice(index, 1);
    }
    setSelectSuggestion(state);
  }

  const handleUpdateFollowTag = () => {
    dispatch(updateFollowTag(selectSuggestion))
      .then(() => {
        history.push("/")
      })
      .catch(() => {
        alert("Houve um erro inesperado.")
      });
  }

  return (
    <section className="FollowTags">
      <div className="follow-tags-home">
        <h3>Melhore a sua experiência.</h3>
        <p>Para receber conteúdos personalizados no Alexandria, comece selecionando as
          tags que tem a ver com você.</p>
        <p>Fique tranquilo, você poderá alterá-las depois.</p>
        <Button children="Escolher tags" onClick={() => scrollBottom()}/>
      </div>
      <div className="follow-tags-container" id="scrollBottom">
        <div className="interests-tags">
          <h3>Quais são seus interesses?</h3>
          <p>Selecione as tags que deseja seguir. Escolha três ou mais para continuar.</p>
        </div>
        <div className="scroll-tags">
          {tagsReference?.map((item, key) => {
            let active = selectSuggestion.indexOf(item.id) !== -1;
            return <TagFollow item={item.name} key={key} active={active} onClick={() => handleClicked(item.id)}/>
          })}
        </div>
        <div className="continue-tags">
          <Button className={`${selectSuggestion.length >= 3 ? "ButtonActive" : "Button"}`} children="Continuar" onClick={() => handleUpdateFollowTag()}/>
        </div>
      </div>
    </section>
  );
};

export default FollowTags;
