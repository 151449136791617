export const TAGS_REQUEST = "TAGS_REQUEST";
export const TAGS_REQUEST_SUCCEEDED = "TAGS_REQUEST_SUCCEEDED";
export const TAGS_REQUEST_FAILED = "TAGS_REQUEST_FAILED";

export const CHECK_FOLLOW_TAG_REQUEST = "CHECK_FOLLOW_TAG_REQUEST";
export const CHECK_FOLLOW_TAG_REQUEST_SUCCEEDED = "CHECK_FOLLOW_TAG_REQUEST_SUCCEEDED";
export const CHECK_FOLLOW_TAG_REQUEST_FAILED = "CHECK_FOLLOW_TAG_REQUEST_FAILED";

export const FOLLOW_TAG_REQUEST = "FOLLOW_TAG_REQUEST";
export const FOLLOW_TAG_REQUEST_SUCCEEDED = "FOLLOW_TAG_REQUEST_SUCCEEDED";
export const FOLLOW_TAG_REQUEST_FAILED = "FOLLOW_TAG_REQUEST_FAILED";

export const UNFOLLOW_TAG_REQUEST = "UNFOLLOW_TAG_REQUEST";
export const UNFOLLOW_TAG_REQUEST_SUCCEEDED = "UNFOLLOW_TAG_REQUEST_SUCCEEDED";
export const UNFOLLOW_TAG_REQUEST_FAILED = "UNFOLLOW_TAG_REQUEST_FAILED";

export const UPDATE_FOLLOW_TAG_REQUEST = "UPDATE_FOLLOW_TAG_REQUEST";
export const UPDATE_FOLLOW_TAG_REQUEST_SUCCEEDED = "UPDATE_FOLLOW_TAG_REQUEST_SUCCEEDED";
export const UPDATE_FOLLOW_TAG_REQUEST_FAILED = "UPDATE_FOLLOW_TAG_REQUEST_FAILED";

export const SUGGESTION_TAGS_REQUEST = "SUGGESTION_TAGS_REQUEST";
export const SUGGESTION_TAGS_REQUEST_SUCCEEDED = "SUGGESTION_TAGS_REQUEST_SUCCEEDED";
export const SUGGESTION_TAGS_REQUEST_FAILED = "SUGGESTION_TAGS_REQUEST_FAILED";
