import React from 'react';
import './ContentMenu.scss';
import CommentIcon from "../../../assets/images/comment-icon.svg";
import FavoriteIcon from "../../../assets/images/favorite-icon.svg";
import FavoriteIconBlack from "../../../assets/images/favorite-icon-black.svg";
import SeeLaterIcon from "../../../assets/images/seelater-icon.svg";
import SeeLaterIconBlack from "../../../assets/images/seelater-icon-black.svg";
import PlayListIcon from "../../../assets/images/playlist-icon.svg";
import {addFavorite} from "../../../redux/actions/favorite";
import {useDispatch, useSelector} from "react-redux";
import {getContent} from "../../../redux/actions/content";
import {useHistory} from "react-router-dom";
import useMobileDevice from "../../../hooks/useMobileDevice";
import MobileShare from "../MobileShare/MobileShare";

const ContentMenu = ({content, setOpenComment, setOpenPlaylist, title}) => {
  const history = useHistory();
  const userData = useSelector(state => state.user?.data);
  const dispatch = useDispatch();
  const [isMobileOrTablet] = useMobileDevice();

  const addContentInFavorite = e => {
    e.preventDefault()
    const payload = {
      platform: "ALEXANDRIA"
    }
    dispatch(addFavorite(content?.id, payload))
      .then(() => {
        dispatch(getContent(content?.id))
      })
      .catch(() => {
        alert("Houve um erro inesperado, tente novamente.")
      });
  }
  const addContentInSeeLater = e => {
    e.preventDefault()
    const payload = {
      platform: "ALEXANDRIA"
    }
    dispatch(addFavorite(content?.id, payload))
      .then(() => {
        dispatch(getContent(content?.id))
      })
      .catch(() => {
        alert("Houve um erro inesperado, tente novamente.")
      });
  }

  return (
    <div className="ContentMenu">
      <div onClick={() => setOpenComment(true)}>
        <button style={{backgroundImage: `url(${CommentIcon})`}} />
        <p>{content?.contributions?.length}</p>
      </div>
      <button onClick={(e) => userData?.username ? addContentInFavorite(e) : history.push("/login")}
        style={{backgroundImage: `url(${content?.isFavorite  ? FavoriteIconBlack : FavoriteIcon})`}}
      />
      <button onClick={(e) => userData?.username ? addContentInSeeLater(e) : history.push("/login")}
        style={{backgroundImage: `url(${content?.isOnSeeLater ? SeeLaterIconBlack : SeeLaterIcon})`}}/>
      <button onClick={() => userData?.username ? setOpenPlaylist(true) : history.push("/login")}
        style={{backgroundImage: `url(${PlayListIcon})`}}/>
      {isMobileOrTablet &&  (
        <MobileShare contentTitle={title} />
      )}
    </div>
  );
};

export default ContentMenu;
