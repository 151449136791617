import React, { useState, useEffect } from "react";
import "./CommentText.scss";
import womanProfile from "../../../assets/images/woman-profile.svg";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../../redux/actions/user";
import { newComment } from "../../../redux/actions/comment";
import SearchTagComment from "./SearchTagComment/SearchTagComment";
import Input from "../form/Input/Input";
import DeleteTag from "../DeleteTag/DeleteTag";
import { getTagsRequest } from "../../../redux/actions/tags";
import { useTextField } from "../../../hooks/formHooks";
import { apiCreateTag } from "../../../redux/api/tags";
import { removeSpecialCharacters } from "../../../helper/removeSpecialCharacters";
import BluePopUp from "../BluePopUp/BluePopUp";

const CommentText = ({ comments, setCommentsText }) => {
  const dispatch = useDispatch();
  const tags = useSelector((state) => state.tags?.data);
  const userData = useSelector((state) => state.user?.data);
  const contentId = useSelector((state) => state.content?.data?.id);
  const [text, setText, validText] = useTextField("");
  const [createCommentPopUp, setCreateCommentPopUp] = useState(false);
  const [createCommentMessage, setCreateCommentMessage] = useState("");
  const [tagList, setTagList] = useState([]);
  const [tagListId, setTagListId] = useState([]);
  const [valueInput, setValueInput] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [nameTag, setNameTag] = useState("");
  const [errorCreateTagLong, setErrorCreateTagLong] = useState(false);

  const resultInput = !valueInput
    ? tags
    : tags
        .filter((item) =>
          item.name.toLowerCase().includes(valueInput.toLocaleLowerCase())
        )
        .filter((item) => item.name !== valueInput);

  const cards = resultInput.slice(0, 4);

  useEffect(() => {
    dispatch(getTagsRequest());
    dispatch(getUserInfo());
  }, [dispatch]);

  const handleClick = (name) => {
    setValueInput(name);
  };

  const removeTag = (name) => {
    setNameTag(name);
    tagList.splice(tagList.indexOf(name), 1);
  };

  const removeTagID = (name) => {
    tagListId.splice(tagListId.indexOf(name.toUpperCase()), 1);
  };

  const changeText = (e) => {
    e.preventDefault();
    setText(e.target.value);
  };

  function addNewComment(comment) {
    const itensComment = Array.from(comments);
    itensComment.push(comment);
    setCommentsText(itensComment);
  }

  const handleComment = () => {
    if (!validText) {
      setCreateCommentPopUp(true);
      setCreateCommentMessage("Preencha o campo para adicionar um comentário");

      setTimeout(() => {
        setCreateCommentPopUp(false);
      }, 1500);
    } else {
      const payload = {
        text: text,
      };
      let tagsToCreate = tagList.filter(
        (tag) => !tags.some((item) => tag === item.name)
      );
      let newTagsPromisses = tagsToCreate.map((tag) =>
        apiCreateTag({
          name: tag,
        })
      );
      Promise.all(newTagsPromisses).then((results) => {
        let newTagIds = results.map((result) => result.id);
        payload.tags = tagListId.concat(newTagIds);
        dispatch(newComment(payload, contentId))
          .then(() => {
            let updateComment = {
              author: {
                id: userData?.id,
                photo: userData?.photo,
                username: userData.username,
              },
              tags: [],
              text: text,
            };
            tagList.map((tag) => {
              let tags = { name: tag };
              updateComment.tags.push(tags);
            });
            addNewComment(updateComment);
            setText("")
            setTagListId([])
            setTagList([])
          })
          .catch(() => {});
      });
    }
  };

  const handleCreateTag = () => {
    if (valueInput.includes(",")) {
      const noSpecialCharacters = removeSpecialCharacters(valueInput);
      if (noSpecialCharacters.length < 2) {
        setValueInput(removeSpecialCharacters(valueInput));
      } else if (noSpecialCharacters.length > 40) {
        setValueInput(removeSpecialCharacters(valueInput));
        setErrorCreateTagLong(true);
      } else {
        setValueInput("");
        tagList.push(noSpecialCharacters);
      }
    }
  };

  return (
    <div className="CommentText">
      <div className="container-comment">
        <div className="comment-profile">
          <img
            src={userData && userData.photo ? userData.photo : womanProfile}
            alt="Foto de perfil do usuário"
          />
          <p>@{userData && userData.username}</p>
        </div>
        <div className="comments-group">
          <form onSubmit={() => setText("")}>
            <textarea
              type="text"
              placeholder="Escreva aqui suas impressões sobre este conteúdo."
              value={text}
              onChange={changeText}
            />
          </form>
          {errorCreateTagLong && (
            <article>
              <p>tag muito longa.</p>
              <span />
            </article>
          )}
          <div className="container-tags">
            <Input
              className={
                errorCreateTagLong ? "error-tag" : ""
              }
              value={valueInput}
              updateState={setValueInput}
              type="text"
              onEnter={() =>
                setErrorCreateTagLong(false)
              }
              placeholder="Adicionar tags"
              onChange={handleCreateTag()}
            />
            <Button
              children="Postar"
              primaryColor="#CFEC59"
              onClick={() => handleComment()}
            />
          </div>
          {valueInput && cards.length > 0 ? (
            <div
              style={{ display: !valueInput ? "none" : "flex" }}
              className="container-search-tags"
            >
              {cards.map((item, key) => (
                <SearchTagComment
                  item={item}
                  key={key}
                  onClick={() => {
                    tagList.push(item.name);
                    tagListId.push(item.id);
                    handleClick(item.name);
                    setValueInput("");
                    setErrorCreateTagLong(false);
                  }}
                />
              ))}
            </div>
          ) : (
            <p className="insert-tag">Insira uma vírgula depois de cada tag</p>
          )}
          <div className="container-delete-tags">
            {tagList.map((item, key) => {
              return (
                <DeleteTag
                  item={item}
                  key={key}
                  onClick={() => {
                    removeTag(item);
                    removeTagID(item);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
      {createCommentPopUp && (
        <BluePopUp
          text={createCommentMessage}
          onClick={() => setCreateCommentPopUp(false)}
        />
      )}
    </div>
  );
};

export default CommentText;
